









































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import CheckedIn from "@/views/services/serviceApply/component/checkedIn.vue"
import ExportButton from "@/components/ExportButton/index.vue";
import {getActivityApplyStatus} from "@/utils/enumToSelectItems";
import { AuditFlowScope } from "@/api/appService";


@Component({
  name: "UnselectedList",
  components: {
    PagedTableView,
    AbSelect,
    CheckedIn,
    ExportButton
  },
})
export default class UnselectedList extends Vue {
  @Prop() trainId?: number;

  editLinkId = 0;

  queryForm = {
    surName: "",
    phone: undefined,
    status:undefined,
    serviceId: 0
  };
  statusList = getActivityApplyStatus();
  exportFieldList = [
    "志愿者编号",
    "姓名",
    "性别",
    "手机号",
    "居住地",
    "报名时间",
    "志愿者星级",
    "志愿者积分",
    "签到次数",
    "状态",
    "服务标题",
    "服务对象类型",
    "服务对象",
    "服务机构",
    "服务时间",
    "报名服务ID"
  ];
  fetchData(params: any) {
    return api.serviceApply.getAll({
      ...params,
      surName: this.queryForm.surName,
      phone: this.queryForm.phone,
      status:this.queryForm.status,
      serviceId: this.trainId,
    })
  }

  created() {
    this.queryForm.serviceId = this.trainId!;
  }

  // 新建
  handleCheckIn(item:any) {
    this.editLinkId = item.id!;
    (this.$refs.editForm as any).show = true;
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }

  handleDetail(item: any) {
    this.$router.push({name: 'serviceApply-Detail', params: {'id': item.id!.toString()}})
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ServiceApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('paged-table-view',{attrs:{"search-function":_vm.fetchData,"use-cache":false},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":"姓名"},model:{value:(_vm.queryForm.surName),callback:function ($$v) {_vm.$set(_vm.queryForm, "surName", $$v)},expression:"queryForm.surName"}})],1),_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":"手机号码"},model:{value:(_vm.queryForm.phone),callback:function ($$v) {_vm.$set(_vm.queryForm, "phone", $$v)},expression:"queryForm.phone"}})],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('el-divider',{staticClass:"query-form-divider"}),_c('export-button',{attrs:{"url":"/api/services/app/ServiceApply/ExportCheckInListToExcel","file-title":"服务签到列表","query-model":_vm.queryForm,"buttonTitle":"导出","is-direct-export":false,"export-fields":_vm.exportFieldList}})]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"志愿者编号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.volunteer.volunteerOrgCode)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"姓名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.applyUser?row.applyUser.surname:'')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"性别"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userExtend.sex)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"手机号码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s((row.applyUser?row.applyUser.phoneNumber:'') || (row.volunteer?row.volunteer.phoneNumber:''))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"签到时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.checkInTime))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"被服务对象"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.service&&row.service.serviceObjectNames?row.service.serviceObjectNames:"")+" ")]}}])}),_c('el-table-column',{attrs:{"label":"实际参与被服务对象"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.serviceObjectNames?row.serviceObjectNames:"")+" ")]}}])}),_c('el-table-column',{attrs:{"label":"审核状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isAudit)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("审核通过")]):(row.isAudit === null)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("待审核 ")]):_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("审核拒绝："+_vm._s(row.auditReason))])]}}])}),_c('el-table-column',{attrs:{"label":"附件"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('attachments-view',{attrs:{"hostId":row.id,"hostType":_vm.hostType}})]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isAudit === null)?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.handleAuditPass(row)}}},[_vm._v("通过")]):_vm._e(),(row.isAudit === null)?_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.handleAuditReject(row)}}},[_vm._v("拒绝")]):_vm._e()]}}])})]},proxy:true}])}),_c('el-dialog',{attrs:{"title":"审核拒绝","visible":_vm.visible,"close-on-click-modal":false,"width":"500px"},on:{"update:visible":function($event){_vm.visible=$event},"close":function($event){_vm.visible=false}}},[_c('el-input',{attrs:{"placeholder":"请输入拒绝原因","autocomplete":"off"},model:{value:(_vm.auditReason),callback:function ($$v) {_vm.auditReason=$$v},expression:"auditReason"}}),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"default"},on:{"click":function($event){_vm.visible=false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleAuditRejectSubmit}},[_vm._v("确定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }